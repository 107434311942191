import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination, useExpanded, useGlobalFilter } from 'react-table';
import classNames from 'classnames';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFoot,
  TableGlobalFilter,
} from '../shared/table';

const StandardTable = React.memo(
  ({ columns, data, defaultPageSize, isSearchable, hasFooter, hasPagination, rowSubComponent }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      allColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: hasPagination ? defaultPageSize : data.length },
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination
    );

    useEffect(() => {
      setPageSize(defaultPageSize);
    }, [data, defaultPageSize, setPageSize]);

    const renderPageSizeSelection = () => {
      if (data.length <= defaultPageSize) {
        return null;
      }

      return (
        <div className="mb-2">
          Show{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {Array.from([1, 2, 3, 4, 5], el => el * defaultPageSize).map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>{' '}
          Records
        </div>
      );
    };

    const renderPagination = () => {
      const totalDisplayedPages = 5; // Number of pages to show before and after the current page

      const generatePageNumbers = () => {
        const pages = [];
        const maxPages = pageCount; // Total pages
        const startPage = Math.max(0, pageIndex - totalDisplayedPages);
        const endPage = Math.min(pageCount - 1, pageIndex + totalDisplayedPages);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }

        // Ensure we show the first page if the current range doesn't include it
        if (startPage > 0) {
          pages.unshift(0); // Always show the first page
          if (startPage > 1) pages.splice(1, 0, '...'); // Add ellipsis if there's a gap
        }

        // Ensure we show the last page if the current range doesn't include it
        if (endPage < maxPages - 1) {
          if (endPage < maxPages - 2) pages.push('...'); // Add ellipsis if there's a gap
          pages.push(maxPages - 1); // Always show the last page
        }

        return pages;
      };

      return (
        <div className="flex justify-between">
          <div className="font-normal self-center">
            {data.length ? (
              <span>
                Showing {pageIndex * pageSize + 1} to{' '}
                {Math.min(pageIndex * pageSize + pageSize, data.length)} of {data.length} records
              </span>
            ) : (
              <span>No records found.</span>
            )}
          </div>

          {pageCount > 1 && (
            <div>
              <button
                type="button"
                className="py-2 px-3 mx-1 hover:bg-gray-200 rounded-sm"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>

              {generatePageNumbers().map((pageNum, index) => (
                <button
                  key={`pagination_page_${index}`}
                  type="button"
                  className={classNames('px-3 py-2 rounded-sm', {
                    'bg-scion-blue text-white': pageIndex === pageNum,
                    'hover:bg-gray-200': pageIndex !== pageNum,
                  })}
                  onClick={() => pageNum !== '...' && gotoPage(pageNum)}
                  disabled={pageNum === '...'}
                >
                  {pageNum === '...' ? '...' : pageNum + 1}
                </button>
              ))}

              <button
                className="py-2 px-3 mx-1 hover:bg-gray-200 rounded-sm"
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
            </div>
          )}
        </div>
      );
    };

    return (
      <React.Fragment>
        {hasPagination && renderPageSizeSelection()}

        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow className="text-center" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  if (column.Header === '_rowspan') {
                    return null;
                  }
                  return (
                    <TableCell
                      rowSpan={column.rowSpan}
                      sortDirection={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            {data.length > defaultPageSize && isSearchable && (
              <TableRow>
                <TableCell className="bg-white" colSpan={allColumns.length}>
                  <TableGlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    onChange={() => {
                      if (pageIndex !== 0) {
                        gotoPage(0);
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <TableRow>
                    {row.cells.map(cell => {
                      return (
                        <TableCell className={cell.column.className} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>

                  {row.isExpanded ? (
                    <TableRow className="no-hover">
                      <TableCell colSpan={allColumns.length}>{rowSubComponent({ row })}</TableCell>
                    </TableRow>
                  ) : null}
                </React.Fragment>
              );
            })}
          </TableBody>

          {hasFooter && (
            <TableFoot>
              {footerGroups.map(footerGroup => (
                <TableRow className="text-center" {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map(column => {
                    if (column.Header === '_rowspan') {
                      return null;
                    }
                    return (
                      <TableCell rowSpan={column.rowSpan} {...column.getFooterProps()}>
                        {column.render('Footer')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableFoot>
          )}
        </Table>

        {hasPagination && renderPagination()}
      </React.Fragment>
    );
  }
);

StandardTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  defaultPageSize: PropTypes.number,
  hasFooter: PropTypes.bool,
  hasPagination: PropTypes.bool,
  isSearchable: PropTypes.bool,
  rowSubComponent: PropTypes.func,
};

StandardTable.defaultProps = {
  columns: [],
  data: [],
  defaultPageSize: 10,
  hasFooter: false,
  hasPagination: true,
  isSearchable: false,
  rowSubComponent: () => undefined,
};

export default StandardTable;
